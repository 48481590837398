import * as R from 'ramda'
import qs from 'query-string'
import Router from 'next/router'

import { formatMeetingCode } from './meetings'
import {
  NUTRITION,
  WORKOUTS,
  HABITS,
  INFO,
  OPEN_TO_SECTION,
  TASKS,
  FEED,
} from 'components/dashboard/clients/client-details/constants'
import { PAGES as CLIENT_DASHBOARD_PAGES } from 'constants/client-dashboard'
import { COACH_MARKETPLACE_PREFS_SURVEY_ID } from '../components/coach-application/constants'

const SERVER_URL_BY_ENV = {
  production: 'https://www.trainwithkickoff.com',
  beta: 'https://beta.trainwithkickoff.com',
  staging: 'https://staging.trainwithkickoff.com',
  growth: 'https://growth.trainwithkickoff.com',
  development: 'http://localhost:3000',
}

const SERVER_URL = SERVER_URL_BY_ENV[process.env.APP_ENV]
const BETA_PRODUCTION_SERVER_URL =
  process.env.APP_ENV === 'beta' ? SERVER_URL_BY_ENV.production : SERVER_URL
const GOOGLE_OAUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth'

// TODO use custom domain
const ASSETS_URL = 'https://d17kyhbf53flmb.cloudfront.net'

const CALENDLY_REDIRECT_URI = `${SERVER_URL}/oauth/calendly`
const CALENDLY_BASE_AUTH_URL = 'https://auth.calendly.com/oauth'
const CALENDLY_OAUTH_URL = `${CALENDLY_BASE_AUTH_URL}/authorize`

const getQuery = () => Router.router?.query || {}

export const omitParams = omittedParams => {
  if (!Router.router) return {}

  const asPath = Router.router.asPath.split('?')[0]
  const asQuery = R.omit(omittedParams, qs.parse(document.location.search))
  const query = R.omit(omittedParams, Router.router.query)

  return {
    href: withParams(Router.router.pathname, query),
    as: withParams(asPath, asQuery),
  }
}

export const surround = (value, prepend, append = null) =>
  R.isNil(value) ? '' : (prepend || '') + value + (append || '')

export const addParams = params => {
  if (!Router.router) return {}

  const asPath = Router.router.asPath.split('?')[0]
  const asQuery = {
    ...qs.parse(document.location.search),
    ...params,
  }
  const query = { ...Router.router.query, ...params }

  return {
    href: `${Router.router.pathname}?${qs.stringify(query)}`,
    as: `${asPath}?${qs.stringify(asQuery)}`,
  }
}

const withParams = R.curryN(2, (path, params = {}) => {
  const queryString = R.isEmpty(params) ? '' : qs.stringify(params)
  const q = R.isEmpty(queryString) ? '' : `?${queryString}`

  return `${path}${q}`
})

export const getLink = href => ({ href, as: href })

const clientDashboardPage = ({ forceExitInfo = false, ...newParams } = {}) => {
  const existingParams = getQuery()

  const params = {
    ...newParams,
    clientId:
      'clientId' in newParams ? newParams.clientId : existingParams.clientId,
    page:
      'page' in newParams
        ? newParams.page
        : existingParams.page || CLIENT_DASHBOARD_PAGES.TODOS,
  }

  // keep info tab open if navigating within same client
  if (
    params.clientId === existingParams.clientId &&
    existingParams.info &&
    !newParams.info &&
    !forceExitInfo
  ) {
    params.info = 1
  }

  const { page, clientId, tab, openToSection, ...rest } = params

  let path = `/dashboard/${page}`

  if (clientId) {
    path += `/${clientId}`

    if (tab) {
      path += `/${tab}`
      if (openToSection) {
        path += `/${openToSection}`
      }
    }
  }

  return withParams(path, rest)
}

const urls = {
  coachReferral: ({ username, freeTrial, ...params }) => {
    let url = `${BETA_PRODUCTION_SERVER_URL}/coaches/${username}`
    if (freeTrial) url += '/free'

    return withParams(url, params)
  },
  clientReferral: ({ slug }) => {
    return `${SERVER_URL}?ref=${slug}`
  },
  coachProfileReview: ({ username: id, isProfileEdit = false }) => ({
    href: `/coach-profile-review?${qs.stringify({
      id,
      no_cache: 1,
      isProfileEdit: +isProfileEdit,
    })}`,
  }),
  workout: ({ slug, screen }) => {
    const q = qs.stringify({ screen })

    return {
      href: `${BETA_PRODUCTION_SERVER_URL}/workouts?${qs.stringify({
        slug,
        screen,
      })}`,
      as: `${BETA_PRODUCTION_SERVER_URL}/workouts/${slug}${q ? '?' + q : ''}`,
    }
  },
  workoutSurvey: ({ slug }) => ({
    href: `/workouts/survey?${qs.stringify({ slug })}`,
    as: `/workouts/${slug}/survey`,
  }),
  workoutShare: ({ slug }) => ({
    href: `/workouts/share?${qs.stringify({ slug })}`,
    as: `/workouts/${slug}/share`,
  }),
  // has to be used from CD
  workoutDetailsFromDashboard: ({
    workoutId,
    editTitle = false,
    isFirstEdit,
    keepHiddenAfterApproval,
  }) => {
    return addParams({
      workoutId,
      editTitle,
      isFirstEdit,
      keepHiddenAfterApproval,
    }).as
  },
  addOpenToSectionParam: openToSection => {
    return addParams({ openToSection })
  },
  dismissWorkoutDetails: () => {
    return omitParams([
      'workoutId',
      'editTitle',
      'isFirstEdit',
      'keepHiddenAfterApproval',
    ])
  },
  dismissAutoScheduler: () => {
    return omitParams(['autoScheduler'])
  },
  googleSignIn: redirect => {
    const params = {
      client_id: process.env.GOOGLE_CLIENT_ID,
      redirect_uri: `${SERVER_URL}/oauth`,
      response_type: 'code',
      access_type: 'online',
      scope: 'openid email',
    }

    if (redirect) {
      params.state = qs.stringify({ redirect })
    }

    return `${GOOGLE_OAUTH_URL}?${qs.stringify(params)}`
  },
  signIn: (params = {}) => withParams('/sign-in', params),
  signOut: (params = {}) => withParams('/sign-out', params),
  clientWorkouts: (params = {}) => {
    return clientDashboardPage({
      ...params,
      tab: WORKOUTS,
    })
  },
  clientFeed: (params = {}) => {
    return clientDashboardPage({
      ...params,
      tab: FEED,
    })
  },
  clientDetails: (params = {}) => {
    return clientDashboardPage({
      ...params,
      tab: WORKOUTS,
    })
  },
  clientDetailsOpenToSection: (params = {}) => {
    return clientDashboardPage({
      ...params,
      tab: WORKOUTS,
    })
  },
  clientAutoScheduler: (params = {}) => {
    return clientDashboardPage({
      ...params,
      tab: WORKOUTS,
      openToSection: OPEN_TO_SECTION.AUTO_SCHEDULER,
    })
  },
  clientTasks: (params = {}) => {
    return clientDashboardPage({
      ...params,
      tab: TASKS,
    })
  },
  clientNutrition: (params = {}) => {
    return clientDashboardPage({
      ...params,
      tab: NUTRITION,
    })
  },
  clientNutritionSettings: (params = {}) => {
    return clientDashboardPage({
      ...params,
      tab: NUTRITION,
      openToSection: OPEN_TO_SECTION.SETTINGS,
    })
  },
  clientHabits: (params = {}) => {
    return clientDashboardPage({
      ...params,
      tab: HABITS,
    })
  },
  clientInfo: (params = {}) => {
    return clientDashboardPage({
      ...params,
      tab: INFO,
    })
  },
  clientShortWorkouts: (params = {}) => {
    return clientDashboardPage({
      ...params,
      tab: WORKOUTS,
      openToSection: OPEN_TO_SECTION.SHORT_WORKOUTS,
    })
  },
  clientCoachSuggestedMeals: (params = {}) => {
    return clientDashboardPage({
      ...params,
      tab: NUTRITION,
      openToSection: OPEN_TO_SECTION.SUGGESTED_MEALS,
    })
  },
  clientSms: clientDashboardPage,
  clientDashboard: clientDashboardPage,
  inboxDashboard: (params = {}) => {
    return clientDashboardPage({
      ...params,
      page: CLIENT_DASHBOARD_PAGES.INBOX,
    })
  },
  clientsDashboard: (params = {}) => {
    return clientDashboardPage({
      ...params,
      page: CLIENT_DASHBOARD_PAGES.CLIENTS,
    })
  },
  todosDashboard: (params = {}) => {
    return clientDashboardPage({
      ...params,
      page: CLIENT_DASHBOARD_PAGES.TODOS,
    })
  },
  recruiterDashboard: (params = {}) => {
    return clientDashboardPage({
      ...params,
      page: CLIENT_DASHBOARD_PAGES.RECRUITER,
    })
  },
  workoutTemplates: () => ({
    href: `/dashboard/workout-library`,
    as: `/dashboard/workout-library`,
  }),
  workoutTemplate: ({ id, ...params }) => ({
    href: withParams(`/dashboard/workout-library`, {
      workoutTemplateId: id,
      ...params,
    }),
    as: withParams(
      `/dashboard/workout-library/?workoutTemplateId=${id}`,
      params
    ),
  }),
  payrollDashboard: (params = {}) => withParams('/dashboard/payroll', params),
  promotions: () => '/dashboard/promotions',
  newCoach: () => `/dashboard/coaches/new`,
  twilioSmsDetails: ({ twilioSid }) => {
    return `https://www.twilio.com/console/sms/logs/${twilioSid}`
  },
  exerciseDashboard: (params = {}) => withParams('/dashboard/exercise', params),
  exerciseDraftDashboard: (params = {}) => {
    return withParams('/dashboard/exercise-drafts', params)
  },
  exerciseDraftDetails: ({ exerciseDraftId }) => ({
    href: `/dashboard/exercise-drafts?exerciseDraftId=${exerciseDraftId}`,
    as: `/dashboard/exercise-drafts/${exerciseDraftId}`,
  }),
  currentWithParams: (params, keepCurrent = false) => {
    if (!process.browser) return ''

    const currentParams = qs.parse(document.location.search)

    const q = `?${qs.stringify({
      ...(keepCurrent ? currentParams : {}),
      ...(params || {}),
    })}`
    return `${document.location.pathname}${q}`
  },
  clientSignUp: (params = {}) => {
    const href = withParams('/client-sign-up', params)

    return { href, as: href }
  },
  coachSignUp: (params = {}) => {
    return urls.coachOnboarding({ slug: 'coach-intro', params })
  },
  coachSignUpSurvey: (params = {}) => {
    const href = withParams('/coach-sign-up', params)

    return { href, as: href }
  },
  clientPreferencesSurvey: (params = {}) => {
    const href = withParams('/client-preferences-survey', params)

    return { href, as: href }
  },
  welcome: (params = {}) => withParams('/welcome', params),
  welcomeSurvey: (params = {}) => withParams('/welcome/survey', params),
  coachManagement: ({ coachId }) =>
    `/dashboard/coaches${coachId ? `/${coachId}` : ''}`,
  coachMatchesTester: () => ({
    href: `/coach-matches-tester`,
  }),
  homePage: ({ slug, ...otherParams } = {}) => {
    const path = slug ? `/${slug}` : '/'
    const href = withParams(path, otherParams)

    return { href, as: href }
  },
  exercisesDashboard: (params = {}) => {
    const { exerciseId, ...otherParams } = params
    // console.log(exerciseId)

    return {
      href: withParams('/dashboard/exercises', params),
      as: withParams(
        `/dashboard/exercises${exerciseId ? `/${exerciseId}` : ''}`,
        otherParams
      ),
    }
  },
  ingredientsDashboard: (params = {}) => {
    const { ingredientId, ...otherParams } = params

    return {
      href: withParams('/dashboard/ingredients', params),
      as: withParams(
        `/dashboard/ingredients${ingredientId ? `/${ingredientId}` : ''}`,
        otherParams
      ),
    }
  },
  recipesDashboard: (params = {}) => {
    const { recipeId, ...otherParams } = params

    return {
      href: withParams('/dashboard/recipes', params),
      as: withParams(
        `/dashboard/recipes${recipeId ? `/${recipeId}` : ''}`,
        otherParams
      ),
    }
  },
  trainingPhasesDashboard: (params = {}) => {
    const { trainingStageId, ...otherParams } = params

    return {
      href: withParams('/dashboard/training-phases', params),
      as: withParams(
        `/dashboard/training-phases${
          trainingStageId ? `/${trainingStageId}` : ''
        }`,
        otherParams
      ),
    }
  },
  habitTemplatesDashboard: (params = {}) => {
    const { habitTemplateId, ...otherParams } = params

    return withParams(
      `/dashboard/habit-templates${
        habitTemplateId ? `/${habitTemplateId}` : ''
      }`,
      otherParams
    )
  },
  coachAccount: (params = {}) => withParams(`/account`, params),
  sourceClients: () => ({
    href: `/source-clients`,
    as: `/acquire-clients`,
  }),
  blog: (params = {}) => {
    const { slug, ...otherParams } = params

    return {
      href: withParams('/blog', params),
      as: withParams(slug ? `/blog/${slug}` : '/blog', otherParams),
    }
  },
  subscribe: ({ token, params = {} }) =>
    withParams(`/subscribe/${token}`, params),
  coachWelcome: (params = {}) => withParams('/coach-welcome', params),
  coachOnboarding: ({ slug, params }) => {
    const href = withParams(`/coaches/onboarding/${slug}`, params)

    return { href, as: href }
  },
  coachClientProblems: ({ id }) => ({
    href: `/coaches/client-problems?${qs.stringify({ id })}`,
    as: `/coaches/${id}/client-problems`,
  }),
  coachMilestoneDeepDive: ({ id, metric }) =>
    `/coaches/${id}/milestone-deep-dive/${metric}`,
  meeting: ({ meetingCode, asCoach = false }, params = {}) => {
    if (asCoach) {
      params.asCoach = true
    }
    return withParams(
      `${BETA_PRODUCTION_SERVER_URL}/meet/${formatMeetingCode(meetingCode)}`,
      params
    )
  },
  coachAgreement: () => ({
    href: `/agreement-to-coach`,
    as: `/agreement-to-coach`,
  }),
  googleSlideTemplate: () => {
    return 'https://docs.google.com/presentation/d/1P4-fJK1FvPOwTcx3NIEkJwB1ezY1l849OLIE_NHPdr8/edit#slide=id.g4a119ca354_32_0'
  },
  coachMetrics: ({ id }) => ({ href: `/coaches/${id}/metrics` }),
  coachAudits: ({ id }) => `/coaches/${id}/audits`,
  coachPromptSettings: () => ({
    href: `/account/prompt-settings`,
  }),
  coachNotificationPreferences: () => ({
    href: `/account/notification-preferences`,
  }),
  workoutPreviewsAdmin: (params = {}) => {
    const { fileId, workoutPreviewFileId, ...otherParams } = params

    let asUrl = '/dashboard/workout-previews'
    if (fileId) {
      asUrl += `/file/${fileId}`
      if (workoutPreviewFileId) {
        asUrl += `/preview/${workoutPreviewFileId}`
      }
    }
    return {
      href: withParams('/dashboard/workout-previews', params),
      as: withParams(asUrl, otherParams),
    }
  },
  coachProfileFaq: () =>
    'https://docs.google.com/document/d/1_0H5X7BkU2zWy2Na4JfSgUEQQnNnwpxWHLLkj1QHbM8/edit?usp=sharing',
  coachPriceSettings: () => '/account/price-settings',
  resourcesForClients: () =>
    'https://hurricane-suggestion-dec.notion.site/Kickoff-Nutrition-Resource-Library-6b310581db0d4a239224ec6076879740',
  guideToGettingClients: () =>
    'https://hurricane-suggestion-dec.notion.site/Beginner-s-Guide-to-Getting-Clients-d8348059d7064fa895b22cd30ebf75cf',
  businessCardBuilder: (params = {}) => {
    const { coachId } = params

    return !!coachId
      ? `/dashboard/business-card-builder/${coachId}`
      : '/dashboard/business-card-builder'
  },
  coachInvitationFlow: () => '/dashboard/invite',
  coachMarketplacePreferences: params => {
    return urls.coachSignUpSurvey({
      surveyId: COACH_MARKETPLACE_PREFS_SURVEY_ID,
      ...params,
    })
  },

  workoutLibrary: (params = {}) => {
    return clientDashboardPage({
      ...params,
      page: CLIENT_DASHBOARD_PAGES.WORKOUT_LIBRARY,
    })
  },
  changePlan: (params = {}) => withParams('/change-plan', params),
  calendlyGetAuthorizationCode: () => {
    const params = {
      client_id: process.env.CALENDLY_CLIENT_ID,
      response_type: 'code',
      redirect_uri: CALENDLY_REDIRECT_URI,
    }

    return `${CALENDLY_OAUTH_URL}?${qs.stringify(params)}`
  },
  contentfulTutorial: ({ slug, startOnPage = undefined }) => ({
    href: withParams(`/coaches/onboarding/${slug}`, { startOnPage }),
    as: withParams(`/coaches/onboarding/${slug}`, { startOnPage }),
  }),
}

export default urls

export { SERVER_URL, BETA_PRODUCTION_SERVER_URL, ASSETS_URL, urls }
