import * as R from 'ramda'

// inserts hyphens between groups of 3 characters
const formatMatcher = /([a-zA-Z0-9]{3})(?=[a-zA-Z0-9]{3})/g
const formatReplacer = '$&-'

export const formatMeetingCode = meetingCode =>
  (meetingCode || '').replace(formatMatcher, formatReplacer)

const toString = x => x && x.toString()

export const parseIdentity = (meeting, json) => {
  if (!json) return {}
  try {
    const parsed = JSON.parse(json)
    const identity = R.evolve(
      {
        clientId: toString,
        coachId: toString,
        userId: toString,
        rid: toString,
      },
      parsed
    )

    const { clientId, coachId, name } = identity
    const { client } = meeting

    const isMeetingClient = clientId && clientId === client?.id
    const isMeetingCoach = coachId && coachId === client?.coach?.id

    return {
      ...identity,
      isMeetingClient,
      isMeetingCoach,
      isAClient: !!clientId,
      isACoach: !!coachId,
      name: isMeetingClient
        ? client?.user?.firstName
        : isMeetingCoach
        ? client?.coach?.user?.firstName
        : name || 'Anonymous User',
    }
  } catch {
    return {}
  }
}
