import * as R from 'ramda'

export const CONTENTFUL_SURVEY_ENTRY_ID = '6dDQaWz6yG5ME1DSW4aEIZ'
export const COACH_APPLICATION_SUBHEADING_TEXT = [
  'Thank you for your interest in helping us make millions of people healthier and fitter!',
  <br />,
  <br />,
  ...R.join(' ', [
    'The following questions are designed to give us a sense for your qualifications and culture fit with Kickoff.',
    'We are a mission driven organization focused on helping clients achieve things they never thought possible.',
    'We want to see if you would be a great addition to our team!',
  ]),
  <br />,
  <br />,
  ...R.join(' ', [
    'Please provide thoughtful responses -',
    "if you are approved, we'll add these answers to your profile to enable prospective clients to better understand your approach and style",
    "(you'll have the opportunity to edit your responses later, though).",
  ]),
  <br />,
  <br />,
  ...R.join(' ', [
    'If you meet our initial qualifications and seem to be a strong fit,',
    'a Coach Manager will email you to provide next steps on our vetting and onboarding process.',
  ]),
]
export const COACH_APPLICATION_SUCCESS_TEXT = [
  'Thank you! We have received your application.',
  <br />,
  <br />,
  R.join(' ', [
    'If you meet our initial qualifications and seem to be a strong fit,',
    'a Coach Manager will email you to provide next steps on our vetting and onboarding process.',
  ]),
]
export const COACH_SURVEY_ID = 25
export const COACH_MARKETPLACE_PREFS_SURVEY_ID = 26

export const COACH_APPLICATION_DUPLICATE_PHONE_MESSAGE =
  'Your phone number is currently connected to a coach profile. No worries! ' +
  'Please email applicants@trainwithkickoff.com to check on the status of your application.'
export const COACH_APPLICATION_DUPLICATE_GMAIL_MESSAGE =
  'Your email is currently connected to a coach profile. No worries! ' +
  'Please email applicants@trainwithkickoff.com to check on the status of your application.'
export const GENERIC_SUBMIT_ERROR_MESSAGE =
  'Heads up! The application could not be submitted. If the problem persists contact <a href="mailto:applicants@trainwithkickoff.com>applicants@trainwithkickoff.com</a>'
